<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
          :headers="headers"
          :data="data"
          :config="config"
          :loaded-options="options"
          :total-elements="totalElements"
          :total-pages="totalPages"
          @onChangeData="onChangeData"
          @handleActions="handle"
        ></crm-table-pageable-new>

      </v-col>
    </v-row>
    <modal-form v-if="modalVisible"
                :visible="modalVisible"
                :id="itemId"
                @closed="handleClose"
                :mode="mode"
                :fields="modalFields"
                :url="url"
                :item="item"
    />
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import ModalForm from '@/components/ModalForm.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'index',
  components: {
    ModalForm,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: 'ID', type: 'defaultItem',
        },
        {
          value: 'code', sortable: false, text: 'Код', type: 'defaultItem',
        },
        {
          value: 'nameRu', sortable: false, text: 'Наименование на рус.', type: 'defaultItem',
        },
        {
          value: 'nameKz', sortable: false, text: 'Наименование на каз.', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      modalVisible: false,
      itemId: null,
      item: {},
      config: {
        pageable: true,
        add: true,
        search: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: 'errordictionary',
      storeName: 'errordictionary',
      options: {
        pageNumber: 1,
        pageSize: 10,
        size: 10,
        page: 1,
        search: '',
        // sort: 'createdDate,desc',
      },
      mode: 'create',
      modalFields: [
        {
          label: 'Наименование рус.', prop: 'nameRu', type: 'STRING', rules: [(v) => !!v],
        },
        {
          label: 'Наименование каз.', prop: 'nameKz', type: 'STRING', rules: [(v) => !!v],
        },
        {
          label: 'Код', prop: 'code', type: 'STRING', rules: [(v) => !!v],
        },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
    statusEnums() {
      return this.$store.getters['entity/getEntity']('statusEnums');
    },
    paymentTypeEnums() {
      return this.$store.getters['entity/getEntity']('paymentTypeEnums');
    },
    data() {
      return this.$store.getters['dictionary/getDictionary'](this.storeName);
    },
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    ...mapActions('dictionary', ['getData', 'removeData']),
    onChangeData(options) {
      let resultOptions = {
        pageSize: options.size,
        pageNumber: options.page,
        page: options.page,
        size: options.size,
        search: options.search,
      };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch((e) => {
        console.log(e);
      });
      this.options = { ...resultOptions };
      this.get();
    },
    async get() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      const response = await this.getData({
        url: formattedUrl,
        params: {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          'search.value': params.search,
          // sort: params.sort,
        },
        storeName: this.storeName,
      });
      if (response) {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / response.pageSize);
      }
    },
    handle(event) {
      switch (event.type) {
        case 'edit':
          if (this.isChief) break;
          this.itemId = event.item.id;
          this.item = this.data.find((i) => i.id === this.itemId);
          this.mode = 'edit';
          this.modalVisible = true;
          break;
        case 'create':
          if (this.isChief) break;
          this.modalVisible = true;
          this.mode = 'create';
          break;
        case 'delete':
          if (this.isChief) break;
          this.removeData({
            url: this.url,
            id: event.item.id,
          }).then(() => {
            this.get();
          });
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.modalVisible = false;
      this.itemId = null;
      this.item = {};
      this.get(this.url);
    },
  },
};
</script>

<style lang="scss">

</style>
